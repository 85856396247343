

button {
  flex: 1;
  height: 100%;
  box-sizing: border-box;

  margin: 0;

  background-color: #181a23;
  color: white;
  border: none;

  font-size: calc(10px + 2vmin);
  font-family: Calibri, monospace;

  &:hover {
    border-bottom: 1px solid rgba(222, 220, 220, 0.68);
    filter: brightness(110%);
  }
}
#header {
  /*border-bottom: 2px solid dodgerblue;*/
  color: white;
  width: 100%;
  display: flex;
  /*height: auto;*/
  justify-content: center;
  align-items: stretch;
  background-color: #181a23;
}
#navBar {
  display: flex;
  flex: 1;
  /*height: 100%;*/
  background-color: #ff9100;
  /*align-items: center;*/
  /*justify-content: center;*/
}
#profile {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /*flex: auto;*/
  /*flex: 2;*/
  /*align-self: flex-end;*/
  border-radius: 10px;

  padding: 0 10px;
  overflow: hidden;
  white-space: nowrap;

  /*background-color: purple;*/
  color: white;
}
#profile_picture {
  margin: 10px;
  object-fit: contain;
  border-radius: 100%;
  width: 60px;
  height: 60px;
}