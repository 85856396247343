.card-display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #2b2f3d;
    padding-bottom: 80px;
    /*margin-bottom: 40px;*/
}

.card-container {
    position: relative;
    display: flex;
    /*flex: 1 1 300px;*/
    width: 22vw;
    height: 22vw;
    border: 1px solid grey;
    border-radius: 10px;
    background-color: #343949;
    margin: 15px;
    /*margin-bottom: 40px;*/
    font-size: 5em;
    align-items: center;
    justify-content: center;
    transform: scale(1);
    transition: all 300ms ease;
}
.card:active {
    /*transform: scale(.97);*/
}
.card-container:hover {
    filter: brightness(90%);
    transform: translateY(-5px);
}

.card {
    /*position: absolute;*/
}
.card-container:hover .card-image {
    width: 50%;
    height: auto;
    opacity: 0.2;
}
.card-container:hover .card-sub-content {
    color: white;
    opacity: 1;
    transform: scale(1);
}
.card-image{
    width: 60%;
    height: auto;
    transition: all 300ms ease;
}

.card-sub-content {
    text-align: left;
    border: 1px white solid;
    border-radius: 10px;
    padding: 10px;
    /*filter: drop-shadow(10px 10px black);*/
    background-color: rgba(255, 0, 0, 0.1);

    position: absolute;
    opacity: 0;
    transform: scale(0);
    font-size: 2rem;
    font-family: monospace;
    transition: all 300ms ease;
}
#css {
    background-color: rgba(2, 21, 189, 0.2);
}
#css {
    /*margin-top: 500px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#css-label-animation-container {
    display: flex;
    flex-direction: row;
    /*position: fixed;*/
    /*left: 50%;*/
    /*top: 50%;*/
    /*transform: translate(-50%, -50%);*/
}

.styled-letter {
    font-size: 15rem;
    font-weight: 800;
}
/*CSS SECTION WITH ANIMATION*/

#letter-1 {
    transform: translateY(33%);
    fill: #61dafb;
}
#letter-2 {
    /*transform: translateY(1em);*/
    /*background-color: #55ff00;*/
    transform: translateY(66%);
    fill: #0215bd;
}
#letter-3 {
    transform: translateY(99%);
    opacity: 0;
    fill: #343949;
}
#image {
    width: 100%;
}
#letter-1,
#letter-2,
#letter-3 {
    opacity: 0;
    transition: all 1s ease;
}
svg:hover #letter-1,
svg:hover #letter-2,
svg:hover #letter-3 {
    opacity: 1;
    transform: translateY(0%);

}